@import 'normalize.css';

@font-face {
    font-family: 'ff-good-headline-web-pro-con';
    src: url('https://use.typekit.net/af/29ccbb/00000000000000000001134a/27/l?primer=e7c58b81a305e1fc09dd5591d751a34891b5b63ab278ac3b87ed8857fdd9b01a&fvd=n4&v=3')
            format('woff2'),
        url('https://use.typekit.net/af/29ccbb/00000000000000000001134a/27/d?primer=e7c58b81a305e1fc09dd5591d751a34891b5b63ab278ac3b87ed8857fdd9b01a&fvd=n4&v=3')
            format('woff'),
        url('https://use.typekit.net/af/29ccbb/00000000000000000001134a/27/a?primer=e7c58b81a305e1fc09dd5591d751a34891b5b63ab278ac3b87ed8857fdd9b01a&fvd=n4&v=3')
            format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 400;
}

#webbot-widget {
    margin: 0;
    font-family: ff-good-headline-web-pro-con, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.4;
    box-sizing: border-box;
    @media only screen and (max-width: 479px) {
        font-size: 13px;
    }
    svg {
        vertical-align: unset;
    }
}
#webbot-widget code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
#webbot-widget p {
    margin: 0;
    padding: 0;
}
#webbot-widget {
    .web-bot-widget {
        &__no-data {
            height: 410px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            & p {
                font-size: 20px;
            }
        }
        &__preloader {
            height: 410px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            & .ant-spin-dot-item {
                background-color: #FF4560;
            }
            & .ant-spin-text {
                color: #FF4560;
            }
        }
    }
    .web-bot-widget-chart {
        display: flex;
        justify-content: center;
        align-items: stretch;
        flex-wrap: wrap;
        &__col {
            flex-basis: 50%;
            overflow: hidden;
            padding: 0 15px;
            box-sizing: border-box;
        }
        &__title {
            text-align: center;
            font-weight: bold;
            font-size: 18px;
        }
    }
}
